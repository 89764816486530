import { Button, Card, CardContent, CardHeader, FormControl, Menu, MenuItem, styled, TextField } from '@mui/material';

import { CARD, MAX_WIDTH_MENU, NTF_COLOR, SUBHEADER } from './constants';
import { hexWithOpacity } from './helpers';
import { IExpandMoreProps, INotificationType } from './types';

//Notification.tsx
export const StyledMenu = styled(Menu)<{ $maxLimit: boolean }>`
  max-height: 80vh;

  & .MuiMenuItem-root {
    padding: 0;
    margin: 4px 0;
  }

  & .MuiList-root {
    padding-bottom: ${({ $maxLimit }) => ($maxLimit ? 0 : '8px')};
  }
`;

export const NotificationChip = styled('span')`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.warning.light};
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const NotificationMenu = styled('div')`
  position: absolute;
  right: 0;
  top: 4px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLimitMessage = styled('div')`
  width: ${`${MAX_WIDTH_MENU}px`};
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px inset;
  background: ${({ theme }) => theme.palette.background.default};
`;

export const StyledEmptyListStub = styled('div')`
  width: ${`${MAX_WIDTH_MENU}px`};
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.7;
`;

//SnackbarNtf.tsx
export const StyledMenuItem = styled(MenuItem)<INotificationType>`
  width: ${`${MAX_WIDTH_MENU}px`};
  min-width: 320px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: ${({ $type }) => NTF_COLOR[$type]};
    border-radius: 1px;
  }
`;

export const StyledCard = styled(Card)<IExpandMoreProps & INotificationType>`
  width: 100%;
  box-shadow: none;
  border: none;
  background: ${({ $type, $expand }) => ($expand ? hexWithOpacity(NTF_COLOR[$type], 0.1) : 'transparent')};
  border-radius: 0;

  ${({ theme }) => `
     transition: ${theme.transitions.create('background', {
       duration: theme.transitions.duration.shortest,
     })};
  `};
`;

export const StyledCardHeader = styled(CardHeader)`
  padding: 10px 15px;

  & .MuiCardHeader-action {
    cursor: pointer;
    margin: 0;
  }

  & .MuiTypography-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledExpandMore = styled(FormControl)<IExpandMoreProps>`
  margin: 0;
  max-height: 24px;

  ${({ theme, $expand }) => `
     transform: ${!$expand ? 'rotate(0deg)' : 'rotate(180deg)'};
     transition: ${theme.transitions.create('transform', {
       duration: theme.transitions.duration.shortest,
     })};
  `};
`;
export const StyledTitle = styled('span')`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 430px;
  font-size: 1em;
  color: ${({ theme }) => theme.palette.text.primary};
  grid-area: ${SUBHEADER.TITLE};
`;

export const StyledSubheaderInfo = styled('div')<{
  gridArea: (typeof SUBHEADER)[keyof typeof SUBHEADER];
  align?: string;
}>`
  color: ${({ theme }) => theme.palette.text.primary};
  justify-self: ${({ align }) => align ?? 'start'};
  opacity: 0.7;
  font-size: 0.85em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-wrap: ${({ align }) => (align ? 'nowrap' : 'wrap')};
`;

export const StyledSubheader = styled('div')`
  display: grid;
  width: 100%;
  grid-row-gap: 5px;
  grid-template-areas:
    '${SUBHEADER.TITLE} ${SUBHEADER.TITLE}'
    '${SUBHEADER.MODULE} ${SUBHEADER.TIMESTAMP}';
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto auto;
`;

export const StyledHeaderActions = styled('div')`
  position: relative;
`;

//Card content
export const StyledTextField = styled(TextField)`
  grid-area: ${SUBHEADER.TITLE};

  & .MuiInputBase-inputMultiline {
    overflow-y: scroll;
  }

  & .MuiInput-root {
    padding: 0;
    min-height: 24px;
  }

  & .MuiInput-root:before {
    content: none;
  }
`;

export const StyledCardContent = styled(CardContent)`
  position: relative;
  padding: 5px 15px 5px 15px !important;
  cursor: default;
  display: grid;
  grid-row-gap: 5px;
  grid-column-gap: 10px;
  grid-template-areas:
    '${CARD.TYPE} ${CARD.MODULE_LINK}'
    '${CARD.ACTIONS} ${CARD.ACTIONS}';
`;

export const StyledAction = styled('div')`
  grid-area: ${CARD.ACTIONS};
  display: grid;
  justify-content: end;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;

  & .MuiButtonBase-root {
    padding: 0 10px;
    margin: 0;
  }
`;

export const StyledDeleteAction = styled(Button)`
  opacity: 0.3;
  padding-right: 10px;
  padding-left: 10px;

  &:hover {
    opacity: 1;
  }
`;

export const StyledCopyAction = styled(Button)<{ $deleting: boolean }>`
  min-width: 100px;
  transition: ${({ theme }) =>
    theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
    })};

  opacity: ${({ $deleting }) => ($deleting ? 0.5 : 1)};

  &:hover {
    opacity: 1;
  }
`;
