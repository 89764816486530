import { Link } from 'react-router-dom';
import { ListItemButton, styled, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TimelineContent } from '@mui/lab';
import {
  Comment as MuiCommentIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  NoteAdd as NoteAddIcon,
} from '@mui/icons-material';
import { blueGrey } from '@mui/material/colors';

import { LabelThemed } from '/shared/LabelThemed';

export const ListItemStyled = styled(ListItemButton)<{ $ltr?: boolean }>`
  position: relative;
  align-items: unset;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  text-align: right;

  ${(props) =>
    props.$ltr &&
    `
      flex-direction: row-reverse;
    `}
`;

export const TimelineContentStyled = styled(TimelineContent)<{ $fullscreen?: boolean }>`
  width: calc(100% - 12px); /* 12px - timeline dot width */
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  ${(props) =>
    !props.$fullscreen &&
    `
      padding-left: 0;
    `}
`;

export const LinkStyled = styled(Link)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;
  color: ${(props) => props.theme.palette.text.primary} !important;
`;

export const LabelThemedStyled = styled(LabelThemed, {
  shouldForwardProp: (prop) => prop !== 'outline',
})<{ outline?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 140px;
  padding: ${(props) => (props.outline ? 0 : 1)}px;
  cursor: inherit;
  min-width: 19px;

  & svg {
    font-size: 17px;
  }
`;

export const MarketLabel = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  padding: 2px 5px;
  background-color: ${blueGrey[600]};
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px;

  & svg {
    user-select: none;
    font-size: 16px;
  }
`;

export const Bullet = styled('span')`
  &:after {
    content: '•';
    display: block;
    margin-bottom: -2px;
    font-size: ${(props) => props.theme.typography.body1.fontSize};
  }
`;

export const AnnotationIcon = styled(MuiCommentIcon)`
  margin-bottom: -3px;
  font-size: 17px;
`;

export const AddIconStyled = styled(NoteAddIcon)`
  color: ${(props) => props.theme.palette.success.main};
`;

export const EditIconStyled = styled(EditIcon)`
  color: ${(props) => props.theme.palette.warning.main};
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  color: ${(props) => props.theme.palette.error.main};
`;

export const AnnotationsWrapper = styled(Typography)`
  order: -1;
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    margin-left: 2px;
    margin-bottom: -2px;
  }
`;

export const Username = styled(Typography)`
  text-decoration: underline;
`;

export const useTooltipStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[700],
  },
}));

export const useActionMessageStyles = makeStyles((theme: Theme) => ({
  entityIcon: {
    color: theme.palette.action.active,
  },
}));
