import { Button, styled } from '@mui/material';
import { SnackbarContent } from 'notistack';

export const KBAR_BACKGROUND_COLOR = '#495057';
export const StyledSnackBarContent = styled(SnackbarContent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  min-width: 300px;
  border-radius: 4px;
  background-color: ${KBAR_BACKGROUND_COLOR};

  &:last-child {
    padding-bottom: 10px;
  }

  ${({ theme }) => `
    color: ${theme.palette.common.white};
  `}
`;

export const StyledSnackBarButton = styled(Button)`
  margin-left: 30px;
`;
