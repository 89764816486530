import { VariantType } from 'notistack';

import { KBAR_BACKGROUND_COLOR } from '/common/Kbar/KBarSnackBar/styles';

export const MAX_WIDTH_MENU = 500;
export const NTF_COLOR: { [K in VariantType]: string } = {
  success: '#43a047',
  error: '#d32f2f',
  info: '#2196f3',
  warning: '#ff9800',
  default: '#2c2c2c',
  kBarSnackBar: KBAR_BACKGROUND_COLOR,
};

export const SUBHEADER = {
  TITLE: 'subheader_title',
  TIMESTAMP: 'subheader_timestamp',
  MODULE: 'subheader_module',
};

export const CARD = {
  TYPE: 'card_type',
  MODULE_LINK: 'card_module_link',
  ACTIONS: 'card_actions',
  TIME: 'card_time',
};
