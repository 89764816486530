import React from 'react';
import { Typography } from '@mui/material';
import { closeSnackbar } from 'notistack';

import { StyledSnackBarButton, StyledSnackBarContent } from '/common/Kbar/KBarSnackBar/styles';

import { IKBarSnackBar } from './types';

export const KBarSnackBar = React.forwardRef<HTMLDivElement, IKBarSnackBar>((props, ref) => {
  const { message, buttonText, customAction, dataTestId } = props;

  const handleClick = () => {
    if (customAction === undefined) {
      return;
    }
    customAction();
    closeSnackbar();
  };

  return (
    <StyledSnackBarContent ref={ref} data-testid={dataTestId ?? ''}>
      <Typography sx={{ padding: '0' }} variant='subtitle1'>
        {message}
      </Typography>
      {buttonText && (
        <StyledSnackBarButton onClick={handleClick} color={'secondary'} variant={'text'}>
          {buttonText}
        </StyledSnackBarButton>
      )}
    </StyledSnackBarContent>
  );
});
