import { useCallback, useMemo } from 'react';
import { QueryKey, useQueryClient, useQuery } from '@tanstack/react-query';
import { Updater } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { Either } from '@playq/irt';

import { getErrorMessage } from '/helpers';

import { unwrapEither } from './unwrapEither';
import { UseQueryOptionsExtended } from './types';

export const useEitherQuery = <LeftType, RightType>(
  queryKeys: QueryKey,
  queryFn: () => Promise<Either<LeftType, RightType | undefined>> | undefined,
  options?: UseQueryOptionsExtended<RightType | undefined, LeftType | Error>
) => {
  const queryClient = useQueryClient();

  const res = useQuery(
    queryKeys,
    () => {
      const queryRes = queryFn();
      if (queryRes !== undefined) {
        return unwrapEither(queryRes);
      }
    },
    {
      initialData: queryClient.getQueryData<RightType | undefined>(queryKeys),
      ...options,
    }
  );

  const errorMessage = useMemo(() => {
    const error = res.error;
    if (error instanceof GenericFailure || error instanceof Error) {
      return getErrorMessage(error);
    }
  }, [res.error]);

  const setQueryData = useCallback(
    (keys: QueryKey, updator: Updater<RightType | undefined, RightType | undefined>) => {
      queryClient.setQueryData<RightType | undefined>(keys, updator);
    },
    [queryClient]
  );

  const mutate = useCallback(
    (updator: Updater<RightType | undefined, RightType | undefined>) => {
      queryClient.setQueryData<RightType | undefined>(queryKeys, updator);
    },
    [queryClient, queryKeys]
  );

  return {
    ...res,
    keys: queryKeys,
    mutate,
    setQueryData,
    errorMessage,
  };
};
