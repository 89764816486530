import { styled } from '@mui/material';
import { Virtuoso } from 'react-virtuoso';

import { INotificationRowProps } from './NotificationRow';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const NotFoundTextWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const List = styled(Virtuoso<INotificationRowProps['data']>, {
  shouldForwardProp: (prop) => prop !== 'widgetMode' && prop !== 'maxTimelineHeight',
})<{
  widgetMode?: boolean;
  maxTimelineHeight?: number;
}>`
  flex: 1;

  ${({ widgetMode, maxTimelineHeight }) => `
     height: ${widgetMode && maxTimelineHeight !== undefined ? `${maxTimelineHeight}px` : '100%'} !important;
  `}
`;
