import { snackbarStorage, snackbarStorageKey } from '/storage/sessionStorage/snackBarHistory';
import { SnackbarService } from '/common/snackbarService';
import { IDefaultSnackBarHistoryItem } from '/common/SnackBar';

export function deleteNotificationFromStorage(method: keyof SnackbarService, timestamp: string): void {
  const snackbarLogs: IDefaultSnackBarHistoryItem[] = JSON.parse(
    snackbarStorage.get(snackbarStorageKey) || '[]'
  ) as IDefaultSnackBarHistoryItem[];

  // Filter out the notification with the matching method and timestamp
  const updatedLogs = snackbarLogs.filter((log) => log.method !== method || log.timestamp !== timestamp);

  // Update the storage with the new logs
  snackbarStorage.set(snackbarStorageKey, JSON.stringify(updatedLogs));
}

export const handleDeleteAllNotifications = () => {
  // Clear notifications from storage
  snackbarStorage.set(snackbarStorageKey, JSON.stringify([]));
};

export function hexWithOpacity(hex: string, opacity: number): string {
  const hexNumbers = hex.replace(/^#/, '');

  if (hexNumbers.length !== 6) {
    throw new Error('Invalid hex color format. It should be 6 characters long.');
  }
  const alpha = Math.round(Math.max(0, Math.min(opacity, 1)) * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${hexNumbers}${alpha}`;
}
