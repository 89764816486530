import { FC, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Collapse,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  ContentCopy as ContentCopyIcon,
  ExpandMore as ExpandMoreIcon,
  ThumbUp as ThumbUpIcon,
} from '@mui/icons-material';

import { clipboardWrite, formatDate, formatFriendlyDate } from '/helpers';

import { deleteNotificationFromStorage } from './helpers';
import {
  StyledAction,
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
  StyledCopyAction,
  StyledDeleteAction,
  StyledExpandMore,
  StyledHeaderActions,
  StyledMenuItem,
  StyledSubheader,
  StyledSubheaderInfo,
  StyledTextField,
  StyledTitle,
} from './styles';
import { ISnackbarNotificationProps } from './types';
import { CARD, SUBHEADER } from './constants';

export const SnackbarNtf: FC<ISnackbarNotificationProps> = ({ notification, forceUpdate }) => {
  const { msg = 'Unknown', module, method, type = 'default', timestamp, moduleLink = 'Unknown' } = notification;

  const [expanded, setExpanded] = useState(false);
  const [copiyng, setCopiyng] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletedNotification, setDeletedNotification] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCopyNotification = () => {
    clipboardWrite(JSON.stringify(notification));
    setCopiyng(true);

    setTimeout(() => {
      setCopiyng(false);
    }, 3000);
  };

  const handleDeleteNotification = () => {
    setDeleting(true);

    setTimeout(() => {
      setDeleting(false);
    }, 8000);
  };

  const confirmDeleteNotification = () => {
    deleteNotificationFromStorage(method, timestamp);
    setDeletedNotification(true);
    forceUpdate();
  };

  const cancelDeleteNotification = () => {
    setDeleting(false);
  };

  const subheader = (
    <StyledSubheader>
      {expanded ? (
        <StyledTextField
          minRows={1}
          maxRows={4}
          variant='standard'
          value={msg}
          size={'small'}
          autoFocus={false}
          focused={false}
          sx={{ gridArea: SUBHEADER.TITLE }}
          multiline
          fullWidth
        />
      ) : (
        <StyledTitle>{msg}</StyledTitle>
      )}
      <StyledSubheaderInfo gridArea={SUBHEADER.MODULE}>Module: {module}</StyledSubheaderInfo>

      <Tooltip title={formatDate(timestamp, 'EEE DDD t:ss')}>
        <StyledSubheaderInfo gridArea={SUBHEADER.TIMESTAMP} align={'end'}>
          {formatFriendlyDate(timestamp)}
        </StyledSubheaderInfo>
      </Tooltip>
    </StyledSubheader>
  );

  const cardContent = (
    <StyledCardContent>
      <TextField
        label='Method/Type'
        variant='standard'
        value={type !== method ? `${method}/${type}` : type}
        size={'small'}
        autoFocus={false}
        focused={false}
        sx={{ gridArea: CARD.TYPE }}
        fullWidth
      />
      <FormControl sx={{ gridArea: CARD.MODULE_LINK }} variant='standard' focused={false} size={'small'} fullWidth>
        <InputLabel htmlFor='full_module_link'>Executed from</InputLabel>
        <Input
          id='full_module_link'
          type={'text'}
          value={moduleLink}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={() => clipboardWrite(moduleLink)} edge='end'>
                <ContentCopyIcon fontSize={'small'} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <StyledAction>
        {deleting ? (
          <ButtonGroup variant={'outlined'} size={'small'} color={'error'}>
            <Button sx={{ padding: '0 10px' }} onClick={confirmDeleteNotification}>
              Confirm delete
            </Button>
            <Button sx={{ padding: '0 10px' }} onClick={cancelDeleteNotification}>
              Cancel
            </Button>
          </ButtonGroup>
        ) : (
          <StyledDeleteAction onClick={handleDeleteNotification} variant={'outlined'} size={'small'} color={'error'}>
            Delete
          </StyledDeleteAction>
        )}

        <StyledCopyAction
          onClick={handleCopyNotification}
          variant={'outlined'}
          size={'small'}
          color={copiyng ? 'success' : 'primary'}
          startIcon={copiyng ? <ThumbUpIcon /> : null}
          $deleting={deleting}
        >
          {copiyng ? ' Copied!' : ' Copy'}
        </StyledCopyAction>
      </StyledAction>{' '}
    </StyledCardContent>
  );

  return deletedNotification ? null : (
    <StyledMenuItem $type={type}>
      <StyledCard $expand={expanded} $type={type}>
        <StyledCardHeader
          action={
            <StyledHeaderActions>
              <StyledExpandMore onClick={handleExpandClick} $expand={expanded} aria-expanded={expanded}>
                <ExpandMoreIcon />
              </StyledExpandMore>
            </StyledHeaderActions>
          }
          subheader={subheader}
          onClick={!expanded ? handleExpandClick : () => {}}
        />

        <Collapse in={expanded}>{cardContent}</Collapse>
      </StyledCard>
    </StyledMenuItem>
  );
};
